import { Component, Input } from '@angular/core';
import { TitleSection } from '../../../core/models';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-title-section',
  templateUrl: './title-section.component.html',
  styleUrl: './title-section.component.scss',
  standalone: true,
  imports: [CommonModule],
})
export class TitleSectionComponent {
  @Input() data: TitleSection | null = null;
}
